import { Script } from '@/components/script';

export const Head = ({ scripts }) => {
    return (
        <>
            {scripts?.length > 0 &&
                scripts?.map((script, key) => {
                    const { script_id, type, strategy, content } = script;
                    return (
                        <Script
                            key={key}
                            script_id={script_id}
                            type={type}
                            strategy={strategy}
                            content={content}
                        />
                    );
                })}
        </>
    );
};
