'use client';

import { useAuth } from '@/lib/hooks/use-auth';
import { Loader } from '@/components/loader';
import { Head } from '@/components/head';
import { Popups } from '@/components/popups';

export const PageLayout = ({ data, children }) => {
    const { authLoading } = useAuth({ auth: data.auth });

    if (authLoading) {
        return <Loader />;
    }
    return (
        <div style={{ ...data?.theme }}>
            {data.head && <Head {...data.head} />}
            {data.popups && <Popups popups={data.popups} />}
            {children}
        </div>
    );
};
