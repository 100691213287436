'use client';

import { RichText } from '@/components/rich-text';
import { Media } from '@/components/media';
import { Link } from '@/components/link';
import { useModal } from '@/lib/hooks/use-modal';
import { Modal } from '@/components/modal';
import { Image } from '@/components/media/elements/image';
import { Icon } from '@/components/icon';
import { VideoModalContent } from '@/components/media/elements/video-modal-content';
import classNames from 'classnames';

export const Tile = ({
    theme,
    title,
    description,
    media,
    variant,
    type,
    sizes,
}) => {
    const { isOpen, setIsOpen } = useModal();
    return (
        <>
            {(media.type === 'image_link' || media.type === 'pdf') && media && (
                <Link
                    href={media.button?.cta_link}
                    className={classNames('tile', {
                        [`bg-${theme}`]: theme,
                        [`tile--${variant}`]: variant,
                    })}
                    target={media.button.target}
                >
                    {media && (
                        <Media
                            wrapperClassName={'tile__media'}
                            wrapperIconClassName={'tile__icon'}
                            media={media.media}
                            sizes={sizes}
                            fill
                            objectFit={'contain'}
                            objectPosition={'center'}
                            disableCaption
                            disableElevation
                        />
                    )}

                    <div className="tile__content">
                        {title && (
                            <RichText className="tile__title" data={title} />
                        )}
                        {description && (
                            <RichText
                                className="tile__description"
                                data={description}
                            />
                        )}
                    </div>
                </Link>
            )}
            {type === 'filter' && (
                <button
                    className={classNames('tile', {
                        [`bg-${theme}`]: theme,
                        [`tile--${variant}`]: variant,
                    })}
                >
                    {media && (
                        <Image
                            fill
                            objectFit={'cover'}
                            objectPosition={'center'}
                            wrapperClassName={'tile__media'}
                            image={media}
                            disableCaption
                            disableElevation
                        />
                    )}
                    {media?.icon && (
                        <Icon
                            wrapperIconClassName={'tile__icon'}
                            icon={media.icon}
                            color={media.color}
                            primary={media.primary}
                            secondary={media.secondary}
                        />
                    )}
                    <div className="tile__content">
                        {title?.value && (
                            <RichText className="tile__title" data={title} />
                        )}
                        {description?.value && (
                            <RichText
                                className="tile__description"
                                data={description}
                            />
                        )}
                    </div>
                </button>
            )}
            {media.type === 'video' && media && (
                <>
                    <button
                        onClick={() => setIsOpen(true)}
                        className={classNames('tile', {
                            [`bg-${theme}`]: theme,
                            [`tile--${variant}`]: variant,
                        })}
                    >
                        {media && media.thumbnail && (
                            <Image
                                fill
                                objectFit={'contain'}
                                objectPosition={'center'}
                                wrapperClassName={'tile__media'}
                                image={media.thumbnail}
                                disableCaption
                                disableElevation
                            />
                        )}
                        {media && media.icon && (
                            <Icon
                                wrapperIconClassName={'tile__icon'}
                                icon={media.icon}
                                color={media.color}
                                primary={media.primary}
                                secondary={media.secondary}
                            />
                        )}
                        <div className="tile__content">
                            {title && (
                                <RichText
                                    className="tile__title"
                                    data={title}
                                />
                            )}
                            {description && (
                                <RichText
                                    className="tile__description"
                                    data={description}
                                />
                            )}
                        </div>
                    </button>
                    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
                        <VideoModalContent
                            video={media}
                            disableModal
                            setIsOpen={setIsOpen}
                        />
                    </Modal>
                </>
            )}
        </>
    );
};
