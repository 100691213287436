'use client';

import { usePathname, useParams, useSearchParams } from 'next/navigation';
import { useState, useEffect } from 'react';
import { ClickBasedPopup } from './elements/click-based-popup';
import { LoadBasedPopup } from './elements/load-based-popup';
import { VideoRedirectBasedPopup } from './elements/video-redirect-based-popup';
import { PdfRedirectBasedPopup } from './elements/pdf-popup';

export const Popups = ({ popups }) => {
    const { locale } = useParams();
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const videoSearchParams = searchParams.get('video');
    const urlSearchParams = searchParams.get('url');

    const path =
        locale === 'default' ? pathname : pathname.replace(`/${locale}`, '');

    const pathWithSearchParams = searchParams.toString()
        ? `${path}?${searchParams.toString()}`
        : null;

    // All click based popups in its own array
    const clickPopups = popups?.filter((popup) => popup.trigger === 'click');

    // All load based popups in its own array
    const loadPopups = popups?.filter((popup) => {
        const pattern = new RegExp(popup.regex);
        if (popup.trigger === 'load' && pattern.test(path)) {
            return popup;
        }
    });

    // The total count for all load based popups, used additionally for showing load based popups sequentially if there are more than one loading at a time.
    const [count, setCount] = useState(loadPopups.length);

    // Logic for if a video popup needs to happen on load if a user had been redirected from a country site that does not legally support video.
    const videoRedirectPattern = new RegExp(
        '^.*?(video=.*|url=.*www.youtube.com.*)$'
    );
    const videoRedirect = videoRedirectPattern.test(pathWithSearchParams);

    const pdfRedirectPattern = new RegExp(
        '^.*?url=(/{2}|(%2F){2})edwardsprod.blob.core.windows.net.*$'
    );
    const pdfRedirect = pdfRedirectPattern.test(pathWithSearchParams);
    useEffect(() => {
        // Ensures that the proper count is set when popups on page change.
        setCount(loadPopups.length);
    }, [popups]);
    return (
        <>
            {/* Popups that are triggered on page load based on if their urls end with video query param */}
            {videoRedirect && (
                <VideoRedirectBasedPopup
                    video={
                        videoSearchParams ? videoSearchParams : urlSearchParams
                    }
                />
            )}

            {/* Popups that are triggered on page load based on if their urls end with url query param */}
            {pdfRedirect && <PdfRedirectBasedPopup url={urlSearchParams} />}

            {/* Popups that are triggered on page load based on if their urls match regex */}
            {loadPopups.map((popup, index) => {
                const order = index + 1;
                return (
                    <LoadBasedPopup
                        key={index}
                        popup={popup}
                        order={order}
                        count={count}
                        setCount={setCount}
                    />
                );
            })}
            {/* Popups that are triggered on link click based on if their href matches regex */}
            {clickPopups.map((popup, index) => {
                return <ClickBasedPopup key={index} popup={popup} />;
            })}
        </>
    );
};
