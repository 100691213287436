import { Modal } from '@/components/modal';
import { useModal } from '@/lib/hooks/use-modal';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { PopupBody } from './popup-body';

export const ClickBasedPopup = ({ popup }) => {
    const { isOpen, setIsOpen } = useModal();
    const [url, setUrl] = useState(null);
    const router = useRouter();

    useEffect(() => {
        // Add a click event listener to the body to detect clicks on links
        const wrapper = document.querySelector('body');
        const handleLinkClick = (e) => {
            const links = wrapper.querySelectorAll('a');
            let link = null;
            for (let item of links) {
                if (item.contains(e.target)) {
                    link = item.getAttribute('href');
                    break;
                }
            }
            if (link) {
                if (link.startsWith('?')) return; // prevents firing for video popups
                // Check for popups that should be triggered on link click
                const pattern = new RegExp(popup.regex);
                // Check if the popup trigger is set to 'click' and if the link matches the popup regex
                if (popup.trigger === 'click' && pattern.test(link)) {
                    // Prevent the default link behavior and set the active popup, URL, and open the popup
                    e.preventDefault();
                    setUrl(link);
                    setIsOpen(true);
                }
            }
        };
        wrapper.addEventListener('click', handleLinkClick);
        // Remove the event listener when the component unmounts
        return () => {
            wrapper.removeEventListener('click', handleLinkClick);
        };
    }, []);
    const handleDecline = () => {
        if (popup.decline_redirect) {
            router.push(popup.decline_redirect);
            setIsOpen(false);
        } else {
            setIsOpen(false);
        }
    };
    const handleAccept = () => {
        setIsOpen(false);
    };
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} {...popup}>
            <PopupBody
                {...popup}
                accept_url={url}
                handleAccept={handleAccept}
                handleDecline={handleDecline}
            />
        </Modal>
    );
};
