import { Modal } from '@/components/modal';
import { useModal } from '@/lib/hooks/use-modal';
import { useEffect } from 'react';

export const PdfRedirectBasedPopup = ({ url }) => {
    const { isOpen, setIsOpen } = useModal();
    if (url) {
        if (!url?.includes('http')) {
            url = `https:${url}`;
        }
    }
    useEffect(() => {
        setIsOpen(true);
    }, []);
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <iframe src={`${url}`} className="modal__pdf"></iframe>
        </Modal>
    );
};
