import { Modal } from '@/components/modal';
import { useModal } from '@/lib/hooks/use-modal';
import { Video } from '@/components/media/elements/video';
import { useEffect } from 'react';

export const VideoRedirectBasedPopup = ({ video }) => {
    const { isOpen, setIsOpen } = useModal();
    useEffect(() => {
        setIsOpen(true);
    }, []);
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <Video
                video={{ button: { cta_link: video }, disable_controls: false }}
            />
        </Modal>
    );
};
