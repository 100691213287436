import Image from 'next/image';
import Logo from '@/assets/images/logo.svg';

export const Loader = () => {
    return (
        <section className="loader">
            <div className="loader__wrapper">
                <Image
                    height={105}
                    width={80}
                    src={Logo}
                    alt="Edwards Lifesciences Logo"
                    priority
                />
                <div className="loader__animation" />
            </div>
        </section>
    );
};
